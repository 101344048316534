import { Controller } from "stimulus"

export default class extends Controller {

  // initialize() {
  //   var _this = this
  //   var form = document.querySelectorAll("[id='new_role']")
  //   if (form.length == 1) {
  //     var inputs = document.querySelectorAll("input[type=checkbox]")
  //     inputs.forEach(input => {
  //       input.disabled = true;
  //     })
  //   }
  // }

}