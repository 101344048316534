function manage_menu() {
  // Menu
  if (window.innerWidth < 764) {
    // vertical-overlay-menu menu-hide ครั้งแรกจะซ่อนเมนูเสมอ
    document.body.classList.add("vertical-overlay-menu")
    document.body.classList.add("menu-hide")
    // remove class ที่ไม่ได้ใช้
    document.body.classList.remove("vertical-menu")
    document.body.classList.remove("menu-open")
    document.body.classList.remove("menu-expanded")
    document.body.classList.remove("menu-collapsed")
  } else {
    // vertical-menu menu-expanded
    document.body.classList.add("vertical-menu")
    if (document.body.classList.contains('menu-expanded')) {
      document.body.classList.remove("menu-collapsed")
    } else {
      if (document.body.classList.contains('menu-collapsed')) {
        document.body.classList.remove("menu-expanded")
      } else {
        // กรณีที่เปลี่ยนจากหน้าจอเล็กมาจะยังไม่มี menu-expanded และ menu-collapsed ให้ default เป็น menu-expanded
        document.body.classList.add("menu-expanded")
      }
    }
    // remove class ที่ไม่ได้ใช้
    document.body.classList.remove("vertical-overlay-menu")
    document.body.classList.remove("menu-open")
    document.body.classList.remove("menu-hide")
  }

  // Logo แสดงรูปเล็กเฉพาะตอนย่อเมนูเท่านั้น
  var content = ''
  if ((document.body.classList.contains('vertical-menu')) && (document.body.classList.contains('menu-collapsed'))) {
    content = document.getElementById('template_logo_s').innerHTML
  } else {
    content = document.getElementById('template_logo').innerHTML
  }
  document.getElementById('main_logo_target').innerHTML = content
}

document.addEventListener('turbo:load', manage_menu);
window.addEventListener("resize", manage_menu);