// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "jquery"
import "popper.js"
import "bootstrap"
import "../stylesheets/application"
import "@hotwired/turbo-rails"
import "@fortawesome/fontawesome-free/css/all"

import flatpickr from "flatpickr"
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbo:load", () => {

  // Date picker
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dataFormat: "Y-m-d",
  })

  // $.fn.setAlertTimeout = function() {
  //   window.setTimeout(function() {
  //     $(".alert").fadeTo(500, 0).slideUp(500, function(){
  //       $(this).remove();
  //     });
  //   }, 2000);
  //
  //   return this;
  // };


})

// import "controllers"

import "controllers"
require("./navman")
