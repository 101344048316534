import { Controller } from "stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {

  initialize() {
    var _this = this
    var rows = document.querySelectorAll("[id*='row_class']")
    rows.forEach(row => {
      row.closest("tr").classList.add(row.textContent.trim())
    })

    var cols = document.querySelectorAll("[data-attr*='row_class']")
    cols.forEach(col => {
      col.remove()
    })

    var cols = document.querySelectorAll("[id*='row_class']")
    cols.forEach(col => {
      col.remove()
    })

    var tbls = document.querySelectorAll("table.table-striped")
    tbls.forEach(tbl => {
      tbl.classList.remove('table-striped')
    })

  }

  row_click(event) {
    event.preventDefault()
    let url = this.element.getAttribute('data-click-url')
    Turbo.visit(url) // ต้องสั่งคำสั่งนี้ด้วย import { Turbo } from '@hotwired/turbo-rails'
  }

}