import { Controller } from "stimulus"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Webcam from '@uppy/webcam'
import ImageCompressor from 'uppy-plugin-image-compressor'
import XhrUpload from '@uppy/xhr-upload'
import Form from'@uppy/form'

export default class extends Controller {
  static targets = ["field"]

  connect() {
    console.log('uppy connect')
    setupUppy(this.fieldTarget)
  }

  initialize() {
    console.log('uppy initialize')
  }
}

function setupUppy(element) {
  // let trigger = element.querySelector('[data-behavior="uppy-trigger"]')
  let form = element.closest('form')
  let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  let field_name = element.dataset.name
  let options = JSON.parse(element.dataset.params)
  let note = element.dataset.note

  // trigger.addEventListener("click", (event) => event.preventDefault())
  let uppy = Uppy({
    // autoProceed: true,
    // allowMultipleUploads: true,
    restrictions: {
      maxFileSize: 5000000,
      maxTotalFileSize: 50000000,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 0,
      allowedFileTypes: ['image/*', 'application/pdf', 'video/*']
    },
    onBeforeFileAdded: (currentFile, files) => {
      let sequence = 1;
      Object.keys(files).forEach(k => {
        if (files[k].meta.sequence >= sequence) {
          sequence = files[k].meta.sequence + 1;
        }
      });
      const ext = currentFile.name.split('.').pop();
      const modifiedFile = {
        ...currentFile,
        name: currentFile.source + '_' + sequence + '.' + ext,
        meta: { sequence }
      };
      return modifiedFile;
    },
    // logger: Uppy.debugLogger,
  })

  // uppy.use(ActiveStorageUpload, {
  //   directUploadUrl: direct_upload_url
  // })
  uppy.use(Dashboard, {
    target: '#drag-drop-area',
    inline: true,
    replaceTargetContent: true,
    showProgressDetails: true,
    note: note,
    browserBackButtonClose: true,
    proudlyDisplayPoweredByUppy: false,
    hideUploadButton: true,
    hideRetryButton: true,
    hidePauseResumeCancelButtons: true
  })
  uppy.use(Webcam, {
    target: Dashboard,
    // countdown: false,
    modes: [
    //   'video-audio',
    //   'video-only',
    //   'audio-only',
      'picture'
    ],
    facingMode: 'environment',
    mirror: false,
    showVideoSourceDropdown: true,
    // locale: {}
  })
  uppy.use(ImageCompressor, {
    maxWidth: 2000,
    maxHeight: 2000,
    quality: 0.8
  })
  uppy.use(XhrUpload, {
    endpoint: form.action,
    fieldName: 'message[attachments][]',
    bundle: true,
    validateStatus: (status, responseText, response) => {
      if ((status < 200) || (status >= 300)) {
        return false;
      }
      const dom = new DOMParser().parseFromString(responseText, 'text/html');
      const errorHead = dom.querySelector('.bs-callout-danger');
      if (errorHead == null) {
        return true;
      }
      var errorHeadItems = errorHead.querySelectorAll('li');
      var categoryErrors = Array.prototype.filter.call(errorHeadItems, function(element) {
        return RegExp(/^Category /).test(element.textContent);
      })
      .map(item => {
        const newDiv = document.createElement("div");
        const newContent = document.createTextNode(item.textContent);
        newDiv.classList.add('invalid-feedback');
        newDiv.appendChild(newContent);
        return {
          categoryNo: item.textContent.match(/^Category (\d+)/)[1],
          error: newDiv
        }
      });
      const form = document.querySelector('form#new_message');
      form.insertBefore(errorHead, form.firstChild);
      const errors = dom.querySelectorAll('.invalid-feedback');
      errors.forEach(error => {
        const parent = error.parentNode;
        const input = parent.querySelector('input,select');
        const domInput = document.getElementById(input.id);
        const domParent = domInput.parentNode;
        domInput.classList.add('is-invalid');
        domParent.appendChild(error);
      });
      categoryErrors.forEach(error => {
        const domInput = document.querySelector('select[name^="message[category][Dashboard_' + error.categoryNo +
          '."], select[name^="message[category][Webcam_' + error.categoryNo + '."]');
        const domParent = domInput.parentNode;
        domInput.classList.add('is-invalid');
        domParent.appendChild(error.error);
      });
      const authenticityToken = document.querySelector('input[name=authenticity_token]');
      const newAuthenticityToken = dom.querySelector('input[name=authenticity_token]');
      authenticityToken.value = newAuthenticityToken.value;
      return false;
    }
  })
  uppy.use(Form, {
    target: '#new_message'
  })
  uppy.setOptions(options.uppy)

  uppy.on('file-added', (file) => {
    const elem = document.getElementById("message_source");
    let meta = JSON.parse(elem.value || '{}');
    meta[file.name] = file.source;
    elem.value = JSON.stringify(meta);

    // Find template and replace: name, id, label
    let category = document.getElementById("category_template").content.cloneNode(true);
    let label = category.querySelector('label');
    let input = category.querySelector('input,select');
    label.innerText = file.meta.name;
    label.htmlFor = `message_category_${file.meta.sequence}`;
    input.id = `message_category_${file.meta.sequence}`;
    input.name = `message[category][${file.meta.name}]`;

    const target = document.querySelector('div.uppy_area').closest('div.row');
    const parent = target.parentNode;
    parent.insertBefore(category, target);
  });

  uppy.on('file-removed', (file) => {
    const elem = document.getElementById("message_source");
    let meta = JSON.parse(elem.value || '{}');
    delete meta[file.name];
    elem.value = JSON.stringify(meta);
    const description = document.getElementById('message_category_' + file.meta.sequence).closest('div.row');
    description.remove();
  });

  uppy.on('upload', (data) => {
    const errorHead = document.querySelector('.bs-callout-danger');
    if (errorHead) {
      errorHead.remove();
    }
    const errors = document.querySelectorAll('.invalid-feedback');
    errors.forEach(error => {
      const parent = error.parentNode;
      const input = parent.querySelector('input,select');
      input.classList.remove('is-invalid');
      error.remove();
    });
  });

  uppy.on('complete', (result) => {
    window.location = '/messages/sent';
  });

  const submit_button = document.querySelector('#new_message input[type="submit"]');
  submit_button.addEventListener('click', (event) => {
    const uppyState = uppy.getState();
    const fileIds = Object.keys(uppyState.files);
    if (!fileIds.length) {
      return true;
    }
    fileIds.forEach(fileId => {
      uppy.setFileState(fileId, { progress: { uploadStarted: null }});
    });
    event.preventDefault();
    uppy.upload();
  })
}

function appendUploadedFile(element, file, field_name) {
  const hiddenField = document.createElement('input')

  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', field_name)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute('value', file.response.signed_id)

  element.appendChild(hiddenField)
}

function setPreview(element, file) {
  let preview = element.querySelector('[data-behavior="uppy-preview"]')
  if (preview) {
    preview.src = file.preview
  }
}
