import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["template_logo", "template_logo_s", "main_logo"]

  toggle(event) {
    if (document.body.classList.contains('vertical-overlay-menu')) {
      document.body.classList.toggle("menu-open")
      document.body.classList.toggle("menu-hide")
    } else {
      document.body.classList.toggle("menu-expanded")
      document.body.classList.toggle("menu-collapsed")
    }
    
    if (document.body.classList.contains('vertical-menu')) {
      if (document.body.classList.contains("menu-collapsed")){
        // var content = this.template_logo_sTarget.innerHTML กดไปกดมาแล้ว error หาไม่เจอ
        var content = document.getElementById('template_logo_s').innerHTML
        this.main_logoTarget.innerHTML = content
      } else {
        // var content = this.template_logo_Target.innerHTML
        var content = document.getElementById('template_logo').innerHTML
        this.main_logoTarget.innerHTML = content
      }
    }
    event.preventDefault()
  }
  submenu(event) {
    event.preventDefault()
    let wrapper = event.target.closest("li.nav-item.has-sub")
    wrapper.classList.toggle("open")
  }
}