import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["template", "add_item"]
  
  sort(event) {
    var urlParams = new URLSearchParams(location.search);
    var attr = event.target.dataset.attr
    var dir = urlParams.get("dir") || 'asc'
    var currentAttr = urlParams.get("sort")
    var currentURL = window.location.origin+window.location.pathname
    if (attr == currentAttr){
      dir = ((dir == 'asc') ? 'desc' : 'asc')
    }
    var gotoURL = currentURL+'?'+'sort='+attr+'&'+'dir='+dir
    // console.log(gotoURL)
    window.location.href = gotoURL
  }
  
  add_association(event) {
    event.preventDefault()
    var element_index = new Date().valueOf()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, element_index)
    this.add_itemTarget.insertAdjacentHTML('beforeend', content)
  }
  
  remove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest(".nested-fields")
    if (wrapper.dataset.newRecord == 'false') {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    } else {
      wrapper.remove()
    }
  }
  
  recal(event){
    let list = JSON.parse(event.target.dataset.params)
    var i
    // this.total_with_taxTarget.value = 123
    for (i = 0; i < list.length; i++) {
      console.log(list[i])
    } 
  }
}